import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
interface StyledPProps {
  fontSize?: string
  fontWeight?: string
}
// 可以参考想看可以看注意看
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 420px;
  padding: 2rem 1rem;
  border-radius: 30px;
  //background: ${({ theme }) => theme.primary6};
`

const StyledP = styled.p<StyledPProps>`
  font-size: ${props => props.fontSize || '20px'};
  margin: 0;
  font-weight: ${props => props.fontWeight || '700'};
  color: ${({ theme }) => theme.text6};
`

export default function FormattedValue({ value, transparent }: { value: number; transparent?: boolean }) {
  const { t } = useTranslation()
  const formattedValue = value.toLocaleString()
  return (
    <StyledDiv
      style={{ marginBottom: transparent?  '-30px' : '-30px', backgroundColor: transparent ? 'transparent' : 'default', marginTop: transparent ? '-30px' : '-30px' }}
    >
      <StyledP fontSize="20px" fontWeight="500" style={{ color: transparent ? '#8b63ba ' : '' }}>
        {t('totalPledgeValue')}
      </StyledP>
      <StyledP fontSize="30px">${formattedValue}</StyledP>
    </StyledDiv>
  )
}
